const DOMAIN = {
  en: 'https://vitesy.com',
  it: 'https://vitesy.com/it',
  de: 'https://vitesy.com/de'
}

// const COOKIE_BANNER = {
//   en: `var _iub = _iub || []; _iub.csConfiguration = {"gdprAppliesGlobally":false,"enableCcpa":true,"ccpaApplies":true,"countryDetection":true,"cookiePolicyInOtherWindow":true,"reloadOnConsent":false,"enableRemoteConsent":true,"askConsentAtCookiePolicyUpdate":true,"perPurposeConsent":true,"enableCMP":true,"googleAdditionalConsentMode":true,"ccpaAcknowledgeOnDisplay":true,"lang":"en","siteId":1140919,"consentOnContinuedBrowsing":false,"cookiePolicyId":41972836,"cookiePolicyUrl":"https://vitesy.com/de/cookie-politik", "banner":{ "slideDown":false,"acceptButtonDisplay":true,"customizeButtonDisplay":true,"rejectButtonDisplay":true,"position":"bottom","acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","textColor":"black","backgroundColor":"white" }};`,
//   de: `var _iub = _iub || []; _iub.csConfiguration = {"gdprAppliesGlobally":false,"enableCcpa":true,"ccpaApplies":true,"countryDetection":true,"cookiePolicyInOtherWindow":true,"reloadOnConsent":false,"enableRemoteConsent":true,"askConsentAtCookiePolicyUpdate":true,"perPurposeConsent":true,"enableCMP":true,"googleAdditionalConsentMode":true,"ccpaAcknowledgeOnDisplay":true,"lang":"en","siteId":1140919,"consentOnContinuedBrowsing":false,"cookiePolicyId":41972836,"cookiePolicyUrl":"https://vitesy.com/de/cookie-politik", "banner":{ "slideDown":false,"acceptButtonDisplay":true,"customizeButtonDisplay":true,"rejectButtonDisplay":true,"position":"bottom","acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","textColor":"black","backgroundColor":"white" }};`,
//   it: `var _iub = _iub || []; _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"ccpaAcknowledgeOnDisplay":true,"consentOnContinuedBrowsing":false,"cookiePolicyInOtherWindow":true,"countryDetection":true,"enableCcpa":true,"enableRemoteConsent":true,"enableTcf":true,"floatingPreferencesButtonDisplay":"anchored-bottom-left","googleAdditionalConsentMode":true,"invalidateConsentWithoutLog":true,"perPurposeConsent":true,"reloadOnConsent":true,"siteId":1140919,"cookiePolicyId":81374808,"lang":"it","cookiePolicyUrl":" https://vitesy.com/it/cookie-policy/","privacyPolicyUrl":" https://vitesy.com/it/privacy-policy/", "banner":{ "acceptButtonCaptionColor":"white","acceptButtonColor":"#000000","acceptButtonDisplay":true,"backgroundColor":"white","closeButtonRejects":true,"customizeButtonCaptionColor":"#FFFFFF","customizeButtonColor":"#000000","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonCaptionColor":"white","rejectButtonColor":"#000000","rejectButtonDisplay":true,"slideDown":false,"textColor":"black" }};`
// }

export default {
  seo(i18nHead, document, uid, lang, uidDefault) {
    const title = document?.seo_title || document?.title?.[0]?.text + ' | Vitesy' || uidDefault + ' | Vitesy'
    const description = document?.seo_description || document?.content?.[0]?.text || document?.description?.[0]?.text

    const seoIndex = document?.seo_index === false ? false : true

    let img_url = `${DOMAIN.en}/img/vitesy_hero.jpg`
    if (document?.seo_image) {
      document.seo_image.url ? (img_url = document.seo_image.url) : ''
    } else {
      if (document?.hero_image) {
        document.hero_image.url ? (img_url = document.hero_image.url) : ''
      } else if (document?.image) {
        document.image.url ? (img_url = document.image.url) : ''
      }
    }
    let img_alt = ''
    if (document?.seo_image) {
      document.seo_image.alt ? (img_alt = document.seo_image.alt) : ''
    } else {
      if (document?.hero_image) {
        document.hero_image.alt ? (img_alt = document.hero_image.alt) : ''
      } else if (document?.image) {
        document.image.alt ? (img_alt = document.image.alt) : ''
      }
    }

    const i18nHeadLink = i18nHead.link.map((l) => { 
      if (!l.href.includes('/de/blog')) {
        return {...l, href: 'https://vitesy.com' + l.href}
      }
    }).filter((l) => l !== undefined)

    const seoData = {
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      headAttrs: {
        prefix: 'og: http://ogp.me/ns#'
      },
      title,
      meta: [
        { hid: 'robots', name: 'robots', content: seoIndex ? 'index' : 'noindex, nofollow' },
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: document?.seo_keywords || ''
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: img_url
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${DOMAIN[lang]}/${uid}`
        },
        // TWITTER
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          content: '@vitesy_tw'
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: title
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: description
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: img_url
        },
        {
          hid: 'twitter:image:alt',
          name: 'twitter:image_alt',
          content: img_alt
        },
        ...i18nHead.meta
      ],
      link: [...i18nHeadLink],
      // script: [
      //   {
      //     type:'text/javascript',
      //     innerHTML: `${COOKIE_BANNER[lang]}`
      //   },
      //   { src:"//cdn.iubenda.com/cs/tcf/stub-v2.js" },
      //   { src:"//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js" },
      //   { src:"//cdn.iubenda.com/cs/ccpa/stub.js" },
      //   { src:"//cdn.iubenda.com/cs/iubenda_cs.js", charset: "UTF-8", async: true }
      // ]
    }
    return seoData
  }
}
