
// import IconsSlice from '~/components/slices/IconsSlice.vue'
// import QuoteSlice from '~/components/slices/QuoteSlice.vue'
// import TextSlice from '~/components/slices/TextSlice.vue'
// import ButtonSlice from '~/components/slices/ButtonSlice.vue'
// import VideoSlice from '~/components/slices/VideoSlice.vue'
// import ImageGallerySlice from '~/components/slices/ImageGallerySlice.vue'
// import SectionSlice from '~/components/slices/SectionSlice.vue'
// import CarouselSlice from '~/components/slices/CarouselSlice.vue'
// import Carousel2Slice from '~/components/slices/Carousel2Slice.vue'
// import PlantsCarouselSlice from '~/components/slices/PlantsCarouselSlice.vue'
// import ColumnsSlice from '~/components/slices/ColumnsSlice.vue'
// import TwoColumnsSlice from '~/components/slices/TwoColumnsSlice.vue'
// import StoresSlice from '~/components/slices/StoresSlice.vue'
// import SectionHeaderSlice from '~/components/slices/SectionHeaderSlice.vue'
// import ContentTypeListSlice from '~/components/slices/ContentTypeListSlice.vue'
// import CardsSlice from '~/components/slices/CardsSlice.vue'
// import PressReviewsSlice from '~/components/slices/PressReviewsSlice.vue'
// import CustomersReviewsSlice from '~/components/slices/CustomersReviewsSlice.vue'
// import CompaniesSlice from '~/components/slices/CompaniesSlice.vue'
// import AwardsSlice from '~/components/slices/AwardsSlice.vue'
// import SubscriptionFormSlice from '~/components/slices/SubscriptionFormSlice.vue'
// import TabsSlice from '~/components/slices/TabsSlice.vue'
// import EteriaComparisonTableSlice from '~/components/slices/EteriaComparisonTableSlice.vue'
// import SmartAssistantSlice from '~/components/slices/SmartAssistantSlice.vue'
// import ReviewscarouselSlice from '~/components/slices/ReviewsCarouselSlice.vue'
// import BannerSlice from '~/components/slices/BannerSlice.vue'
// import VideoSectionSlice from '~/components/slices/VideoSectionSlice.vue'
// import ImageContentSliderSlice from '~/components/slices/ImageContentSliderSlice.vue'
// import BoxContentImagesSlice from '~/components/slices/BoxContentImagesSlice.vue'
// import AppfeaturesSlice from '~/components/slices/AppfeaturesSlice.vue'
// import ProductFeaturesSlice from '~/components/slices/ProductFeaturesSlice.vue'
// import HepaComparisonSlice from '~/components/slices/HepaComparisonSlice.vue'
// import TableSlice from '~/components/slices/TableSlice.vue'
// import AddToCartButtonSlice from '~/components/slices/AddToCartButtonSlice.vue'
// import TilesSlice from '~/components/slices/TilesSlice.vue'
// import CtaBoxSlice from '~/components/slices/CtaBoxSlice.vue'
// import BusinessFormSlice from '~/components/slices/BusinessFormSlice.vue'
// import BusinessSupportSlice from '~/components/slices/BusinessSupportSlice.vue'
// import BlogPostListSlice from '~/components/slices/BlogPostListSlice.vue'
// import SliderSlice from '~/components/slices/SliderSlice.vue'
// import ServiceFeaturesSlice from '~/components/slices/ServiceFeaturesSlice.vue'
// import HorizontalCardsSlice from '~/components/slices/HorizontalCardsSlice.vue'
// import FoodComparisonSlice from '~/components/slices/FoodComparisonSlice.vue'
// import TrustpilotWidgetSlice from '~/components/slices/TrustpilotWidgetSlice.vue'
// import ProductFeaturesSliderSlice from '~/components/slices/ProductFeaturesSliderSlice.vue'
// import ServicesBannerSlice from '~/components/slices/ServicesBannerSlice.vue'
// import ComparisonSlice from '~/components/slices/ComparisonSlice.vue'
// import HeaderImageCardsSlice from '~/components/slices/HeaderImageCardsSlice.vue'
// import ShelfyPageSlice from '~/components/slices/ShelfyPageSlice.vue' //TEMP

export default {
  name: 'SlicesBlock',
  // components: {
  //   IconsSlice,
  //   QuoteSlice,
  //   TextSlice,
  //   ButtonSlice,
  //   VideoSlice,
  //   ImageGallerySlice,
  //   SectionSlice,
  //   CarouselSlice,
  //   ColumnsSlice,
  //   TwoColumnsSlice,
  //   StoresSlice,
  //   SectionHeaderSlice,
  //   ContentTypeListSlice,
  //   PressReviewsSlice,
  //   AwardsSlice,
  //   SubscriptionFormSlice,
  //   TabsSlice,
  //   CustomersReviewsSlice,
  //   CompaniesSlice,
  //   EteriaComparisonTableSlice,
  //   CardsSlice,
  //   Carousel2Slice,
  //   PlantsCarouselSlice,
  //   SmartAssistantSlice,
  //   ReviewscarouselSlice,
  //   BannerSlice,
  //   VideoSectionSlice,
  //   ImageContentSliderSlice,
  //   BoxContentImagesSlice,
  //   AppfeaturesSlice,
  //   ProductFeaturesSlice,
  //   HepaComparisonSlice,
  //   TableSlice,
  //   AddToCartButtonSlice,
  //   TilesSlice,
  //   CtaBoxSlice,
  //   BusinessSupportSlice,
  //   BlogPostListSlice,
  //   BusinessFormSlice,
  //   SliderSlice,
  //   ServiceFeaturesSlice,
  //   HorizontalCardsSlice,
  //   FoodComparisonSlice,
  //   TrustpilotWidgetSlice,
  //   ProductFeaturesSliderSlice,
  //   ServicesBannerSlice,
  //   ComparisonSlice,
  //   HeaderImageCardsSlice,
  //   ShelfyPageSlice //TEMP
  // },
  props: ['slices']
}
